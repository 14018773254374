import React, { Component } from "react";
import styled from "styled-components";

import Seo from "../SeoHead";
import Background from "./Background";
import Text from "./Text";
import BackButton from "./Back";
import Footer from "./Footer";
import Helmet from 'react-helmet'

const Section = styled.section`
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
  padding: 2rem;
`;

const Content = styled.div`
  max-width: 30rem;
`;

export default class Thanks extends Component {
  state = {
    redeemCode: ''
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : ((r & 0x3) | 0x8)
      return v.toString(16)
    })
  }

  trackJoinWaitlist() {
    window.analytics.track({ event: '[MK] Join Waitlist', anonymousId: this.uuidv4() });
  }

  componentDidMount() {
    const redeemCodeItem = localStorage.getItem('redeem-code')

    if (redeemCodeItem) {
      this.setState({ redeemCode: redeemCodeItem })
    }
  }

  render() {
    const { content } = this.props

    return (
      <>
        <Seo />
        <Background />
        <Section>
          <Content>


            <Text text={content.text} title={content.title} header={content.header} />


            <BackButton />
            <Footer />
          </Content>
        </Section>
        <Helmet>
          <script>
            {`
            // reddit conversion pixel
            !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','t2_4h7ljdp3');rdt('track', 'PageVisit');
            `}
          </script>
          <noscript>
            {`
            <img height="1" width="1" src="https://www.facebook.com/tr?id=1374162532740157&ev=PageView &noscript=1"/>
            `}
          </noscript>
          <script>
            {`
            // twitter conversion pixel
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','o2mec');
            twq('track','PageView');
            `}
          </script>
          <script>
            {`
            // google conversion pixel
              window.dataLayer = window.dataLayer || [];

              gtag('event', 'conversion', {'send_to': 'AW-724584625/rC-GCN36wqoBELGRwdkC'});
            `}
          </script>
        </Helmet>
      </>
    );
  }
}
