import React from "react";
import styled from "styled-components";
import { H2, P } from "../Typography";
import PlayNowButton from "components/Header/PlayNowButton";


const Text = styled.div`
  width: 100%;
  padding: 2rem 0;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  .download-badge-link {
    width: auto;
    height: 100%;
    max-width: 100%;
  }

  .download-badge-link.google-play {
    width: 200px;
    height: auto;
  }

  .download-badge-links {
    justify-content: space-around;
    margin-bottom: 40px;
    flex-direction: row;
    margin-top: 20px;
    display: flex;
    height: 60px;
  }

  @media (max-width: 740px) {
    .download-badge-links {
      align-items: center;
      margin: 40px 0;
      flex-direction: column;
    }

    .download-badge-link {
      width: 200px;
      margin-bottom: 12px;
    }
  }
`;

const PDiv = P.withComponent('div')

const ThanksText = ({ text, title, header }) => {
  return (
    <Text>
      {header}

      {!!title && <H2 color="#fff">{title}</H2>}

      <div>
        <P style={{ textAlign: 'center' }}>Skyweaver is in Open Beta right now, so you can jump in the game and play right away!</P>

        <div style={{
          marginBottom: "4rem",
          textAlign: "center"
        }}><PlayNowButton /></div>
      </div>


      {typeof text === "string" && !!text ? (
        <PDiv>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </PDiv>
      ) : (
        text
      )}
    </Text>
  );
}

export default ThanksText

