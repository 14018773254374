import React from 'react'
import styled from 'styled-components'

import Image from '../Image'

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;

  figure, img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const Background = () =>
  <StyledBackground>
    <Image src='/images/thanks/background.jpg' />
  </StyledBackground>

export default Background