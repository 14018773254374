import "../css/main.css";
import "../css/fonts.css";

import React, { Component } from "react";
import Image from "../components/Image";
import { Link } from "gatsby";
import styled from "styled-components";

import data from "../data";
import Thanks from "../components/Thanks";
import { LS_THANKYOU_PAGE_TYPE } from "../constants";

const { thankYouPage, creatorsThankYouPage, accessThankYouPage } = data;

export default class ThanksPage extends Component {
  state = {
    creatorsThankYou: false,
  };

  componentDidMount() {
    this.setState({
      contentType: localStorage.getItem(LS_THANKYOU_PAGE_TYPE)
    });
  }

  render() {
    const { contentType } = this.state;

    let pageData = thankYouPage

    if (contentType === 'creators') {
      pageData = creatorsThankYouPage
    }

    if (contentType === 'access') {
      pageData = accessThankYouPage
    }

    return (
      <div>
        <Thanks
          renderPlayButton
          content={{
            title: pageData.title,
            text: pageData.text,
            header: (
              <Skyweaver>
                <Link to="/">
                  <Image src="/images/skyweaver-beta.svg" alt="Skyweaver" />
                </Link>
              </Skyweaver>
            )
          }}
        />
      </div>
    );
  }
}

const Skyweaver = styled.div`
  margin-bottom: 2rem;

  figure {
    width: 100%;
    text-align: center;

    img {
      width: 60%;
    }
  }
`;
